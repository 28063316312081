.mainPage {
    flex: 5;
    background-color: #f5faf6;
}

.mainSummary {
    width: 100%;
    justify-content: space-between;
}

.mainSummaryTitle {
    font-size: 20px;
    font-weight: bold;
    color: #004C26;
}

.mainSummaryContainer {
    margin: 10px;
    padding: 10px;
    /* -webkit-box-shadow: 0px 0px 11px -1px #cccccc;  */
    /* box-shadow: 0px 0px 11px -1px #cccccc; */
}

.mainSummaryFlexContainer {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-around;
}

.mainSummaryFlexItem {
    flex: 1;
    /* margin: 5px 5px; */
    padding: 5px;
    -webkit-box-shadow: 0px 0px 1px -1px #cccccc;
    box-shadow: 0px 0px 11px -1px #cccccc;
}

.calendar-heatmap {
    margin-top: 10px;
    width: 100%;
    height: 100px;
}


/* Heatmap color scale */

.react-calendar-heatmap .color-empty {
    fill: #eeeeee;
}

.react-calendar-heatmap .color-filled {
    fill: #8cc665;
}

.react-calendar-heatmap .color-0 {
    fill: #eeeeee;
}

/* [  "#1e6823",  "#35662b",  "#4d6032",  "#645a39",  "#7b533f",  "#924c46",  "#aa464d",  "#c14354",  "#d83c5a",  "#f13561",  "#a51603"] */

.react-calendar-heatmap .color-1 {
    fill: #1e6823;
}

.react-calendar-heatmap .color-2 {
    fill: #35662b;
}

.react-calendar-heatmap .color-3 {
    fill: #4d6032;
}

.react-calendar-heatmap .color-4 {
    fill: #645a39;
}

.react-calendar-heatmap .color-5 {
    fill: #7b533f;
}

.react-calendar-heatmap .color-6 {
    fill: #924c46;
}

.react-calendar-heatmap .color-7 {
    fill: #aa464d;
}

.react-calendar-heatmap .color-8 {
    fill: #c14354;
}

.react-calendar-heatmap .color-9 {
    fill: #d83c5a;
}

.react-calendar-heatmap .color-10 {
    fill: #f13561;
}

.super {
    vertical-align: sub;
    font-size: medium;
}

.container {
    display: flex;
    flex-direction: row;
    align-items: stretch;
}

.chart1 {
    flex-basis: 80%;
    padding: 10px;
}

.chart2 {
    flex-basis: 20%;
    display: flex;
    flex-direction: column;
    align-items: stretch;
}

.chart2>div {
    flex-basis: 50%;
}