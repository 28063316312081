.topbar{
    width: 100%;
    height: 100px;
    background-color: #FFFFFF;
    position: sticky;
    top:0;
    z-index: 999;
}

.topbarWrapper{
    height: 100%;
    padding: 0px 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.topRight{
    display: flex;
    align-items: center;
}

.logo{
    height: 50%;
}

.logoImg{
    height: 75px;
}

.topbarIconContainer{
    position: relative;
    cursor: pointer;
    margin-right: 10px;
    color: #555;
}

.topIconBadge{
    position: absolute;
    top: -5px;
    right: 0px;
    background-color: red;
    color: white;
    border-radius: 60%;
    width: 15px;
    height: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 10px;
}