.homePage {
    flex: 5;
    background-color: #f5faf6;
    padding: 20px; /* Add some padding for spacing */
  }
  
  .contentContainer {
    display: flex;
    justify-content: space-between;
  }
  
  .textColumn {
    width:85%; /* Adjust the width as needed */
    padding-left: 30px; /* Add some spacing between the text and image */
  }
  
  .imageColumn {
    width:15%; /* Adjust the width as needed */
    border-radius: 8px;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.1); /* Add a subtle box shadow for depth */
  }
  
  .image {
    width: 100%;
    height: auto;
    border-radius: 8px;
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.1); /* Add a subtle box shadow for depth */
  }
  