.sidebar {
    flex: 1;
    background-color: #ecf8ee;
    height: calc(100vh - 50px);
    position: sticky;
    top: 120px;
}

.sidebarWrapper {
    padding: 20px;
    color: #555;
}

.sidebarMenu {
    margin-bottom: 10px;
}

.sidebarTitle {
    font-size: 24px;
    color: rgb(9, 63, 38);
}

.sidebarList {
    list-style: none;
    padding: 5px;
}

.sidebarListItem {
    padding: 5px;
    cursor: pointer;
    display: flex;
    align-items: center;
    border-radius: 10px;
}

.sidebarListItem:hover {
    background-color: rgb(193, 219, 205);
}

.sidebarListItem.active {
    background-color: rgb(150, 197, 172);
}

.sidebarIcon {
    margin-right: 5px;
    font-size: 20px !important;
    color: #1c75bb;
}

.sidebarLink {
    text-decoration: none;
    color: #004c26;
}