.emission-chart {
    display: flex;
  }
  
  .column {
    flex: 1;
    padding: 10px;
    margin-top: 10px;
  }

  .column-headings {
    margin-bottom: 5px;
  }

  