.enterPage{
    flex: 5;
    background-color: #ecf3f7;
}

.enterUsage{
    width: 100%;
    justify-content: space-between;
}

.enterUsageTitle{
    font-size: 20px;
    font-weight: bold;
    color:#004C26;
}

.enterUsageContainer{
    margin: 20px;
    padding: 20px;
    -webkit-box-shadow: 0px 0px 11px -1px #cccccc; 
    box-shadow: 0px 0px 11px -1px #cccccc;
}

.enterUsageFlexContainer{
    display:flex;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: space-around;
}

.enterUsageFlexItem{
    flex: 1;
    margin: 5px 5px;
    padding: 5px;
/*       -webkit-box-shadow: 0px 0px 1px -1px #cccccc; 
    box-shadow: 0px 0px 11px -1px #cccccc;   */
}

.enterTrend{
    width: 100%;
    justify-content: space-between;
}

.enterTrendTitle{
    font-size: 20px;
    font-weight: bold;
    color:#004C26;
}

.enterTrendContainer{
    margin: 20px;
    padding: 20px;
    -webkit-box-shadow: 0px 0px 11px -1px #cccccc; 
    box-shadow: 0px 0px 11px -1px #cccccc;
}

.enterTrendFlexContainer{
    display:flex;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: space-around;
}

.enterTrendFlexItem{
    flex: 1;
    margin: 5px 5px;
    padding: 5px;
/*      -webkit-box-shadow: 0px 0px 1px -1px #cccccc; 
    box-shadow: 0px 0px 11px -1px #cccccc;  */
}

.enterForm label {
    font-weight: bold;
  }
  
  .enterForm select,
  .enterForm input {
    margin-top: 5px;
    padding: 5px;
    width: 100%;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  .enterForm button {
    margin-top: 10px;
    padding: 8px 16px;
    background-color: #004c26;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .enterForm button:hover {
    background-color: #003518;
  }
  
  .enterForm button:disabled {
    background-color: #ccc;
    cursor: not-allowed;
  }